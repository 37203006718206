$dark-sapphire: #093657;
$dark-sapphire-gradient: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);
$extra-dark-sapphire-gradient: linear-gradient(180deg, #074470 0%, #240333 100%);
$light-paper-gradient: linear-gradient(180deg, #FDFBF5 0%, #FAF3DD 19.79%, #FAF3DD 80.73%, #F7E9C0 100%);
$orange-gradient: linear-gradient(180deg, #F5B40E 0%, #FD671A 100%);
$lake-gradient: linear-gradient(180deg, #F5FAFA 0%, #DCF1F2 100%);
$sapphire: #0F8EB7;
$light-gold: #FAF3DD;
$gold: #F4C10C;
$white: #FAFCFC;
$lake: #23B5C9;
$titling-font-family: "Coustard", serif;
$dark-orange: #632917;
$black: #212121;
$orange: #FD671A;