.Checkbox{
  font-size: inherit;

  label{
    display: flex;


    .labels{
      .label{
        display: block;
        font-size: 0.8em;
        flex-grow: 1;
        padding-right: 1em;

        p{
          margin: 0;
          font: inherit;
        }
      }

      .error{
        display: block;
        margin-bottom: 0.333em;
        font-size: 0.8em;
        color: red;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .control{
      display: block;
      position: relative;
      line-height: 0;

      input{
        opacity: 0;
        width: 1px;
        height: 1px;
        position: absolute;
      }

      .controlItem{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
        width: 1.8em;
        height: 1.8em;
        background-color: white;
        border-radius: .4em;
        border: 1px solid;
        outline-width: 2px;
        outline-style: solid;
        outline-color: transparent;

        .symbol{
          opacity: 0;

          svg{
            width: 1.2em;
            height: auto;
          }
        }
      }
    }
  }
}