@import "../../style/variables";

.Items{
  min-height: 100vh;
  background-image: linear-gradient(180deg, #074470 0%, #240333 100%);
  color: $white;

  .header{
    position: relative;
    padding: 54px 23px 42px;

    .exit-wrapper{
      position: absolute;

      .CustomButton{
        border-radius: 10px;
        margin-top: -3px;
      }
    }

    .title-wrapper{
      text-align: center;
      font-family: $titling-font-family;
      font-weight: 900;
      font-size: 28px;
    }
  }

  .placeCTA{
    padding: 23px;
    margin-bottom: 42px;

    text-align: center;
    background-color: $sapphire;
  }

  .tabSelector{
    padding: 0 23px 33px;
    margin-top: -10px;
    display: flex;
    color: $light-gold;

    .tab{
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      text-align: center;
      padding: 0;
      padding-bottom: 10px;
      border: none;
      border-bottom: 1px solid;
      display: block;
      appearance: none;
      background-color: transparent;
      color: inherit;
      font-size: inherit;
      font-family: inherit;
      cursor: pointer;

      &.active{
        border-bottom-width: 4px;
        font-weight: bold;
      }
    }
  }

  .purpose{
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    padding: 0 20px;
    font-size: .9em;
  }

  .list{
    padding-bottom: 84px;
    
    .single{
      margin-bottom: 40px;

      .header{
        padding: 0 23px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &, &:visited, &:active, &:hover, &:focus, &:link{
          text-decoration: none;
        }
  
        .name{
          font-family: $titling-font-family;
          font-weight: 900;
          font-size: 18px;
          color: $light-gold;
          padding-right: 10px;
        }
  
        .counter{
          font-weight: 700;
          font-size: 16px;
          color: $gold;
          white-space: nowrap;
  
          svg{
            margin-left: 13px;
          }
        }
      }
  
      .items{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        overflow-x: auto;
  
        .scrollable{
          background-color: $sapphire;
          padding: 23px;
          display: flex;
          width: fit-content;
          min-width: 100%;
  
          .item{
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            height: 100px;
            overflow: hidden;
            padding: 10px;
            border-radius: 20px;
            background: $light-paper-gradient;
            box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
            appearance: none;
            cursor: pointer;
            border: none;

            &.skin{
              padding: 0;

              &.active{
                outline: 2px solid $orange;
                filter: drop-shadow(0px 0px 4px rgba(253, 103, 26, 0.33));
              }
            }
  
            &:not(:last-child){
              margin-right: 8px;
            }
  
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              filter: drop-shadow(0px 0px 4px rgba(253, 103, 26, 0.33));
            }

            .zoomed{
              height: 100%;
              width: 100%;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;

              &.floor{
                background-size: 172%;
                background-position: bottom;
              }

              &.wall{
                background-size: 230%;
              }

              &.furniture{
                background-size: 120%;
                background-position: bottom;
              }

              &.decoration{
                background-size: 280%;
                background-position: center left;
              }

            }
          }
        }

        .empty{
          background-color: $sapphire;
          padding: 23px;
          text-align: center;
          font-weight: bold;
          color: $light-gold;

          a{
            &, &:link, &:visited, &:hover, &:focus, &:active{
              color: $gold;
            }
          }
        }
      }
    }
  }

  .go-wrapper{
    position: fixed;
    bottom: 0;
    transition: .2s transform;
    text-align: center;
    left: 0;
    right: 0;
    background-color: red;
    padding-top: 20px;
    padding-bottom: 60px;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    opacity: 0;

    &.active{
      opacity: 1;
    }
  }
}

.ios .Items{
  min-height: -webkit-fill-available;
}

.ios .Items .list .singleMuseum .museumItems{
  overflow-x: scroll;
}