@import "../../style/variables";

.Museum{
  background-color: $light-gold;
  min-height: 100vh;

  .whole-wrapper{
    padding: 32px 12px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .header{
    display: flex;
    justify-content: space-between;

    .left{
      display: flex;
      align-items: center;

      .image{
        padding-right: 10px;
        font-size: 0;
  
        img{
          width: 44px;
          height: 44px;
          object-fit: cover;
          border-radius: 22px;
        }
      }
  
      .text{
        line-height: 1.2;
        padding-bottom: 5px;
  
        .name{
          font-family: $titling-font-family;
          font-weight: 900;
          font-size: 20px;
          color: $black;
        }
  
        .address{
          color: $dark-orange;
          font-size: 10px;
        }
      }
    }

    .right{
      a{
        display: inline-flex;
        height: 28px;
        width: 40px;
        color: $orange;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .info-wrapper{
    padding-top: 20px;
    text-align: center;

    .Collapsible{
      display: flex;
      flex-direction: column;

      .Collapsible__trigger{
        order: 2;
      }

      .Collapsible__contentOuter{
        order: 1;
      }
    }

    .CustomButton{
      font-size: .7em;
    }

    .info-list{
      text-align: left;
      padding-bottom: 20px;

      .links-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 20px 0;

        a{
          &, &:link, &:visited, &:active, &:hover, &:focus{
            background: linear-gradient(180deg, #FD671A 0%, #950A4D 100%);
            width: 36px;
            height: 36px;
            border-radius: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 11px;

            svg{
              height: 16px;
              width: auto;
            }
          }
        }
      }

      .text-wrapper{
        font-size: 12px;

        p{
          margin: 0;

          b, strong{
            font-weight: bold;
          }

          a{
            &, &:link, &:visited, &:active, &:hover, &:focus{
              color: $sapphire;
              text-decoration: underline;
              font-weight: bold;
            }
          }
        }

        h2{
          margin: 20px 0 0;
          font-size: 12px;
          color: $orange;
          text-transform: uppercase;
        }

        &>*:first-child{
          margin-top: 0 !important;
        }
      }
    }

    hr{
      color: $gold;
      border-style: solid;
      margin: 30px 0 0;
    }
  }

  .actions-wrapper{
    &>*{
      margin-top: 20px;
      display: block;
    }

    .no-experience{
      border-radius: 20px;
      border: 2px solid;
      color: $dark-orange;
      padding: 10px 15px;
      text-align: center;
      font-size: .9em;
    }

    a{
      &, &:link, &:visited, &:active, &:hover, &:focus{
        color: white;
        text-decoration: none;

        .experience-link{
          width: 100%;
          aspect-ratio: 1;
          display: block;
          background-color: $dark-sapphire;
          border-radius: 20px;
          overflow: hidden;
          filter: drop-shadow(0px 4px 8px rgba(253, 103, 26, 0.4));
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
    
          .text{
            height: 100%;
            background: linear-gradient(360deg, #090B39 0%, rgba(9, 66, 110, 0) 71.18%, rgba(228, 241, 253, 0.54) 100%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 20px 30px;
    
            .top{
              display: flex;
              align-items: center;

              .title{
                font-family: $titling-font-family;
                font-weight: 900;
                font-size: 28px;
                flex-grow: 1;
              }

              .icon{
                font-size: 0;
              }
            }
            
            .bottom{
              padding-top: 10px;

              .bar{
                background-color: $light-gold;
                height: 6px;
                overflow: hidden;
                border-radius: 3px;

                .partial{
                  background-color: #85B62A;
                  height: 6px;
                }
              }
            }
          }
        }
      }
    }

    .additionalAction{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      height: 44px;
      border-radius: 22px;
      background: linear-gradient(180deg, #074470 0%, #240333 100%);
      box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;

      svg{
        margin-bottom: 2px;
        margin-left: 10px;
      }
    }
  }
}

.ios .Museum{
  min-height: -webkit-fill-available;
}