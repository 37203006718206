@import "../../style/variables";

.Profile{
  min-height: 100vh;
  background-color: $light-gold;
  padding: 42px 32px 94px;

  &.fixMode{
    padding-bottom: 32px;

    .section.sectionSave{
      margin-bottom: 0;
    }
  }

  .wrapper{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .logout-wrapper{
    font-size: 18px;
    text-align: right;
    margin-bottom: 47px;

    button{
      box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
    }
  }

  .section{
    margin-bottom: 47px;

    h2{
      font-size: 20px;
      font-weight: 900;
      font-family: $titling-font-family;
      color: $dark-sapphire;
      margin: 0 0 20px;
    }

    a{
      text-decoration: underline;
    }

    .Input, .Select, .Checkbox{
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    &.sectionSave{
      text-align: center;
    }

    &.sectionAbout{
      p{
        margin-top: 0;
        margin-bottom: 1em;

        a{
          color: $sapphire;
        }
      }
    }


    &.sectionDelete{
      p{
        margin-top: 0;
        margin-bottom: 23px;

        a{
          color: $sapphire;
        }
      }

      .CustomButton{
        color: red;
      }
    }

  }
}