@import "../../style/variables";

.BottomNavigation{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 5px 30px;
  background-color: $dark-sapphire;
  border-top: 2px solid $lake;

  nav{
    display: flex;

    a{
      &, &:link, &:active, &:visited, &:focus, &:hover{
        color: inherit;
        text-decoration: none;
      }
  
      flex-grow: 1;
      width: 100%;
      display: block;
      text-align: center;

      .text{
        display: block;
        text-align: center;
        font-weight: bold;
        font-size: 10px;
        color: $white;
      }

      &.active{
        .text{
          color: $orange;
        }
      }
    }
  }
}