@import "./style/variables";

.spinner{
  display: block;
  height: 40px;
  width: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg{
    width: 100%;
    height: 100%;
    background: transparent;
  }
}