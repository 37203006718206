*{
  box-sizing: border-box;
}

.gmnoprint.gm-style-cc{
  display: none;
}

body {
  min-height: 100vh;
  margin: 0 !important;
  scroll-behavior: smooth;
}

html{
  scroll-behavior: smooth;
}

#root, .App{
  min-height: 100vh;
  background-color: #093657;
}

.ios, .ios body, .ios #root, .ios .App{
  min-height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}