.Select{
  font-size: inherit;

  label{
    .labels{
      margin-bottom: 0.333em;
      display: flex;

      .label{
        display: block;
        font-size: 0.8em;
        flex-grow: 1;
      }

      .error{
        font-size: 0.8em;
        color: red;
        text-align: right;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .control{
      display: block;
      position: relative;

      select{
        appearance: none;
        border: none;
        background-color: white;
        height: 2.222em;
        padding: 0 1em;
        border-radius: 1.111em;
        width: 100%;
        font-size: inherit;
        outline-offset: 2px;
        box-sizing: border-box;

        &:focus{
          border: none;
          outline-width: 2px;
          outline-offset: 2px;
        }
      }

      .caret{
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1em;
      }
    }
  }
}