@import "../../style/variables";

.Media{
  color: $light-gold;
  padding: 30px 6px;
  line-height: 1.3;
  font-size: 18px;
  
  .swiper{
    width: 100%;
    padding-bottom: 30px;

    .swiper-wrapper{
      align-items: center;
    }

    .swiper-pagination{
      top: unset;
      bottom: 0;
    }

    .swiper-pagination-bullet{
      background-color: white;

      &.swiper-pagination-bullet-active{
        background-color: white;
      }
    }


  }

  .title-wrapper{
    color: $light-gold;
    font-family: $titling-font-family;
    font-size: 28px;
    font-weight: 900;
    padding: 40px 15px 40px;
    text-align: center;
  }

  .audio-wrapper{
    padding: 0 30px;

    audio{
      width: 100%;
    }
  }

  .video-wrapper{
    font-size: 0;

    video{
      width: 100%;
    }

    .controls{
      .timeIndicator{
        .progressBar{
          background-color: rgba(0,0,0, .5);

          span{
            height: 4px;
            background-color: $light-gold;
            display: inline-block;
            transition: .5s width;
          }
        }
      }

      .buttons{
        padding-top: 25px;
        text-align: center;
        color: $light-gold;

        button{
          appearance: none;
          border: none;
          background-color: transparent;
          cursor: pointer;
          margin: 0 10px;
          color: inherit;

          svg{
            height: 25px;
            width: auto;
          }
        }
      }
    }
  }

  .image-wrapper{
    img{
      width: 100%;
    }
  }

  .file-wrapper{
    text-align: center;

    .image-wrapper{
      padding: 20px;
      
      img{
        width: 100%;
      }
    }
  }

  .description{
    font-size: .8em;
    text-align: center;
    padding: 5px;
  }

  .related-wrapper{
    padding-top: 30px;
    text-align: center;

    .related-title-wrapper{
      font-weight: bold;
      padding-bottom: 10px;
      text-transform: uppercase;
    }

    .related-list-wrapper{
      a{
        &, &:link, &:visited, &:hover, &:active, &:focus{
          text-decoration: underline;
          color: inherit;
          display: block;
          padding-bottom: 10px;
        }
      }
    }
  }
}