@import "../../style/variables";

.ItemModalOverlay{
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  padding: 40px 15px;
  transition: .3s opacity;
  opacity: 0;

  &.visible{
    opacity: 1;
  }

  .ItemModal{
    background: $light-gold;
    overflow: auto;
    border-radius: 26px;
    outline: none;
    padding: 30px;
    box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
    min-height: 100%;
    transition: .3s transform;
    transform: translateY(40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    &.visible{
      transform: translateY(0);
    }

    .inner-wrapper{
      &>.close-wrapper{
        position: absolute;
        top: 15px;
        right: 15px;

        
        button{
          appearance: none;
          border: none;
          background-color: transparent;
          color: $orange;
          padding: 5px;
        }
      }

      &>.image-wrapper{
        padding: 0 15px;

        img{
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          object-fit: contain;
          filter: drop-shadow(0px 4px 8px rgba(253, 103, 26, 0.4));
        }
      }

      &>.title-wrapper{
        padding-top: 40px;

        h2{
          margin: 0;
          font-family: $titling-font-family;
          font-weight: 900;
          font-size: 28px;
          text-align: center;
          color: $dark-sapphire;
        }
      }

      &>.link-wrapper{
        padding-top: 40px;
        font-size: 13px;
        color: $dark-sapphire;
        text-align: center;
      }

      &>.medias-wrapper{
        padding-top: 40px;
        text-align: center;

        p{
          margin: 0;
          color: $dark-sapphire;
          font-size: 13px;
        }

        .CustomButton{
          font-size: 13px;
          margin-top: 10px;
          background: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);
        }
      }

      &>.room-actions-wrapper{
        padding-top: 40px;
        text-align: center;
      }
    }
  }

  &.watchingMedia{
    padding: 0;

    .ItemModal{
      border-radius: 0;
      background-image: $dark-sapphire-gradient;
      padding: 0;

      .inner-wrapper{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;

        .Media{
          flex-grow: 1;
        }

        .back-wrapper{
          color: $white;
          text-align: center;
        }
      }
    }
  }
}