@import '../../style/variables';

$horizontal-padding: 5px;
$vertical-padding: 60px;

.Station{
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);

  .wrapper{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    width: 100%;

    .Moment{
      min-height: 100vh;
      width: 100%;
      background-image: $dark-sapphire-gradient;
      color: $light-gold;
      font-weight: 300;
      font-size: 18px;
      line-height: 1.3;
      transition: .2s background-image;
      
      .total-wrapper{
        opacity: 0;
        transition: .2s opacity;
        min-height: 100vh;
      }

      &.visible .total-wrapper{
        opacity: 1;
      }

      b, strong{
        font-weight: bold;
      }

      .exit-wrapper{
        padding: calc($vertical-padding / 2) 0 0 calc($vertical-padding / 2.2);

        .CustomButton{
          border-radius: 10px;
          background: $extra-dark-sapphire-gradient;
        }
      }

      .balloon-wrapper{
        padding: 22px 22px 26px;
        background-color: $white;
        border: 2px solid $lake;
        box-shadow: 0px 0px 6px rgba(11, 119, 161, 0.33);
        border-radius: 32px 0px 32px 32px;

        .name{
          font-weight: 900;
          font-size: 20px;
          color: $sapphire;
          margin-bottom: 12px;
          font-family: $titling-font-family;
          transition: .2s .2s opacity;
        }

        .text{
          color: $dark-sapphire;
          transition: .2s .2s opacity;

          @media (orientation: landscape) and (max-height: 450px) {
            font-size: .8em;
          }

          &>p{
            margin: 0;
          }
        }
      }

      .actions-wrapper{
        padding-top: 20px;
        text-align: center;
      }

      .game-cleared{
        &>*:first-child{
          opacity: 0.3;
          transition: .5s opacity;
        }

        @keyframes fadeIn {
          0% {opacity: 0; transform: translate(-50%, 0%);}
          100% {opacity: 1; transform: translate(-50%, -50%);}
        }

        .game-congratulations{
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $sapphire;
          font-family: $titling-font-family;
          font-size: 28px;
          font-weight: 900;
          animation-name: fadeIn;
          animation-duration: .5s;
          animation-fill-mode: forwards;

          &.red{
            color: red;
          }
        }
      }

      &.type-dialogue{

        .total-wrapper{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-image: $light-paper-gradient;

          .image-wrapper{
            font-size: 0;
            margin-top: -80px;
            overflow: hidden;
            pointer-events: none;

            img{
              margin-left: auto;
              width: 88%;
              display: block;
              max-width: 600px;
              opacity: 0;
              transform: translateX(40px);
              transition: .5s .2s opacity, .5s .2s transform;
              max-height: calc(100vh - 390px);
              object-fit: contain;

              @media (orientation: landscape) and (max-height: 450px) {
                max-height: unset;
                width: 40%;
                margin-right: 100px;
              }
            }
          }

          .bottom-wrapper{
            padding: $horizontal-padding $horizontal-padding $vertical-padding;
            background: $dark-sapphire-gradient;
            border-top-left-radius: 37px;

            @media (orientation: landscape) and (max-height: 450px) {
              padding-bottom: 30px;
            }

            .balloon-wrapper{
              opacity: 0;
              transform: translateY(20px);
              transition: .5s .5s opacity, .5s .5s transform;
            }

            .actions-wrapper{
              opacity: 0;
              transform: translateY(20px);
              transition: .5s 1s opacity, .5s 1s transform;
              display: flex;
              align-items: center;
              justify-content: center;

              .kind-outline{
                width: 40px;
                height: 40px;
                padding: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-right: 40px;
              }
            }
          }
        }

        &.visible .total-wrapper{
          .image-wrapper img{
            opacity: 1;
            transform: translateX(0);
          }

          .bottom-wrapper {
            .balloon-wrapper{
              opacity: 1;
              transform: translateY(0);
            }

            .actions-wrapper{
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }

      &.type-itemGiven, &.type-skinGiven{
        .total-wrapper{
          padding: $vertical-padding $horizontal-padding;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .balloon-wrapper{
            opacity: 0;
            transform: translateY(20px);
            transition: .5s .2s opacity, .5s .2s transform;
          }
        
          .bottom-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            padding-top: 30px;
            
            .image-wrapper{
              @keyframes glow {
                from {
                  filter: drop-shadow(0px 0px 32px rgba(250, 252, 252, 0.6));
                  transform: translateY(0);
                }
                to {
                  filter: drop-shadow(0px 0px 62px rgba(250, 252, 252, 1));
                  transform: translateY(-10px);
                }
              }

              img{
                width: 90%;
                max-width: calc(81vh - 320px);
                margin-left: auto;
                margin-right: auto;
                display: block;
                filter: drop-shadow(0px 0px 32px rgba(250, 252, 252, 0.6));
                opacity: 0;
                transition: .5s .5s opacity;
                animation-name: glow;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-direction: alternate;

                @media (orientation: landscape) and (max-height: 450px) {
                  max-width: unset;
                  width: 40%;
                }
              }
            }

            .text-wrapper{
              padding: 10px 10px 30px;
              text-align: center;
              opacity: 0;
              transition: .5s .8s opacity;

              .title-wrapper{
                font-family: $titling-font-family;
                font-size: 28px;
                font-weight: 900;
              }

              &.link-wrapper{
                font-size: .8em;
              }
            }

            .actions-wrapper{
              opacity: 0;
              transform: translateY(20px);
              transition: .5s 1s opacity, .5s 1s transform;
            }
          }
        }

        &.visible .total-wrapper{
          .balloon-wrapper{
            opacity: 1;
            transform: translateY(0);
          }

          .bottom-wrapper {
            .image-wrapper img{
              opacity: 1;
              aspect-ratio: 1;
              object-fit: contain;
              border-radius: 30px;
            }

            .text-wrapper{
              opacity: 1;
            }

            .actions-wrapper{
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }

      &.type-skinGiven{
        .total-wrapper .bottom-wrapper .image-wrapper img{
          width: 70%;
          animation: none;
          margin-bottom: 30px;
          object-fit: contain;
          object-position: bottom;
        }

        .total-wrapper .bottom-wrapper .text-wrapper .suggestion-wrapper{
          margin-top: 30px;
        }
      }

      &.type-partialQuestItem{
        background: linear-gradient(180deg, #074470 0%, #041d42 100%);

        .total-wrapper{
          padding: $vertical-padding $horizontal-padding;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        
          .bottom-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            padding-top: 30px;
            
            .image-wrapper{
              @keyframes glow {
                from {
                  filter: drop-shadow(0px 0px 32px rgba(250, 252, 252, 0.6));
                  transform: translateY(0);
                }
                to {
                  filter: drop-shadow(0px 0px 62px rgba(250, 252, 252, 1));
                  transform: translateY(-10px);
                }
              }

              img{
                width: 90%;
                max-width: calc(81vh - 320px);
                margin-left: auto;
                margin-right: auto;
                display: block;
                filter: drop-shadow(0px 0px 32px rgba(250, 252, 252, 0.6));
                opacity: 0;
                transition: .5s .5s opacity;
                animation-name: glow;
                animation-duration: 1s;
                animation-iteration-count: infinite;
                animation-direction: alternate;

                @media (orientation: landscape) and (max-height: 450px) {
                  max-width: unset;
                  width: 40%;
                }
              }
            }

            .text-wrapper{
              padding: 0 10px 30px;
              text-align: center;
              opacity: 0;
              transition: .5s .8s opacity;

              .little-text-wrapper{
                color: white;
              }

              .title-wrapper{
                font-family: $titling-font-family;
                font-size: 28px;
                font-weight: 900;
                color: white;
              }
            }

            .actions-wrapper{
              opacity: 0;
              transform: translateY(20px);
              transition: .5s 1s opacity, .5s 1s transform;
            }
          }
        }

        &.visible .total-wrapper{
          .balloon-wrapper{
            opacity: 1;
            transform: translateY(0);
          }

          .bottom-wrapper {
            .image-wrapper img{
              opacity: 1;
            }

            .text-wrapper{
              opacity: 1;
            }

            .actions-wrapper{
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }

      &.type-additionalMedia{
        .total-wrapper{
          display: flex;
          flex-direction: column;

          .main-wrapper{
            padding: 30px 6px;
            display: flex;
            flex-direction: column;
            flex: 1;

            .balloon-wrapper{
              margin-bottom: 60px;
              opacity: 0;
              transform: translateY(20px);
              transition: .5s .2s opacity, .5s .2s transform;
            }

            .media-list-wrapper{
              flex-grow: 1;
              opacity: 0;
              transform: translateY(20px);
              transition: .5s .5s opacity, .5s .5s transform;

              .CustomButton{
                margin-bottom: 20px;
                width: 100%;
                text-align: center;
              }
            }

            .skip-wrapper{
              text-align: center;
            }
          }

          .Media{
            flex-grow: 1;
          }

          .actions-wrapper{
            padding-bottom: 60px;
          }
        }

        &.visible .total-wrapper{
          .main-wrapper{
            .balloon-wrapper{
              opacity: 1;
              transform: translateY(0);
            }

            .media-list-wrapper{
              opacity: 1;
              transform: translateY(0);
            }
          }
        }
      }

      &.game{

        .total-wrapper{
          display: flex;
          flex-direction: column;
          background: #FAF3DD;
          display: flex;
          flex-direction: column;

          .main-wrapper{
            padding: 30px 6px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .balloon-wrapper{
              box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
              margin-bottom: 30px;
            }

            .bottom-wrapper{
              flex-grow: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      @keyframes fadeIn {
        0% {opacity: 0; transform: translate(-50%, -50%);}
        40% {opacity: 1; transform: translate(-50%, -120%);}
        55% {opacity: 1; transform: translate(-50%, -120%) scale(1.3);}
        75% {opacity: 1; transform: translate(-50%, -120%) scale(1);}
        80% {opacity: 1; transform: translate(-50%, -120%) scale(1.3);}
        100% {opacity: 1; transform: translate(-50%, -120%) scale(1);}
      }

      @keyframes fadeInOut {
        0% {opacity: 0; transform: translate(-50%, -50%);}
        30% {opacity: 1; transform: translate(-50%, -120%);}
        45% {opacity: 1; transform: translate(-50%, -120%) scale(1.3);}
        65% {opacity: 1; transform: translate(-50%, -120%) scale(1);}
        70% {opacity: 1; transform: translate(-50%, -120%) scale(1.3);}
        90% {opacity: 1; transform: translate(-50%, -120%) scale(1);}
        100% {opacity: 0; transform: translate(-50%, -120%) scale(1);}
      }

      &.type-orderingGame{
        .total-wrapper{
          .main-wrapper{
            .bottom-wrapper{
              flex-direction: column;

              .main-wrapper{
                width: 100%;
                
                .right-steps-wrapper{
                  margin-bottom: 40px;
                  width: 100%;
                  padding: 0 15px;
  
                  .StepFeedback{
                    display: flex;
                    width: 100%;
                    margin: 9px 0;
                    align-items: center;
  
                    .step-number{
                      font-weight: bold;
                      color: black;
                      margin-right: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
  
                    .step-wrap{
                      flex-grow: 1;
                      height: 34px;
                      display: flex;
                      align-items: center;
                      border-radius: 17px;
                      border: 1.5px dashed $gold;
                      font-weight: 700;
                      font-size: 12.5px;
                      text-transform: uppercase;
                      padding: 0 20px;
                      width: 100%;
                      min-width: 0;
  
                      .text{
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    }
  
                    &.done{
                      .step-wrap{
                        background: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);
                        box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
                        color: $white;
                        border: none;
                      }
                    }
                  }
                }
  
                .answer-list-wrapper{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
  
                  .AnswerBox{
                    position: relative;
                    padding: 0 10px;
  
                    .text{
                      width: fit-content;
                      font-weight: 700;
                      font-size: 12.5px;
                      text-transform: uppercase;
                      background: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);
                      box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
                      color: $white;
                      border-radius: 23px;
                      padding: 10px 20px;
                      margin: 10px 0;
                      max-width: 310px;
                      text-align: center;
                      transition: .2s all;
                    }
  
                    .ErrorIcon, .RightIcon{
                      position: absolute;
                      pointer-events: none;
                      transform: translate(-50%, -50%);
                      animation-name: fadeInOut;
                      animation-duration: 1s;
                      animation-fill-mode: forwards;
                    }
  
                    &.done{
                      color: gray;
                      pointer-events: none;
  
                      *{
                        pointer-events: none;
                      }
                    }
  
                    &.done{
                      .text{
                        color: $dark-sapphire;
                        outline: 2px solid $dark-sapphire;
                        background: transparent;
                        box-shadow: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.type-imageTapGame{
        .total-wrapper{
          .main-wrapper{
            .bottom-wrapper{
              .image-tap-wrapper{
                .interactive-wrapper{
                  position: relative;

                  img, video{
                    width: 100%;
                  }

                  .ClickableHotspot{
                    position: absolute;

                    .check{
                      position: absolute;
                      transform: translate(-50%, -50%);
                      animation-name: fadeIn;
                      animation-duration: .8s;
                      animation-fill-mode: forwards;

                      img, svg{
                        width: 26px;
                        height: auto;
                      }
                    }
                  }

                  .ErrorIcon{
                    position: absolute;
                    pointer-events: none;
                    transform: translate(-50%, -50%);
                    animation-name: fadeInOut;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;

                    img, svg{
                      width: 26px;
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.type-multipleChoiceGame{
        .total-wrapper{
          .main-wrapper{
            transition: .2s .5s opacity;

            &.visible{
              .bottom-wrapper{
                opacity: 1;
              }
            }

            &.locked{
              pointer-events: none;
            }

            .bottom-wrapper{
              flex-direction: column;
              opacity: 0;
              transition: .4s opacity;

              &.game-cleared{
                &>*:first-child{
                  opacity: 0;
                  transition: .2s opacity;
                }
              }

              .game-wrapper{
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                opacity: 0;
                transition: .4s .5s opacity;

                &.visible{
                  opacity: 1;
                }

                &>div{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                }

                .image-wrapper{
                  padding: 0 20px 40px;

                  &:first-child{
                    flex-grow: 1;
                  }
                  
                  img{
                    box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
                    width: 100%;
                  }

                  &.hide-shadow{
                    img{
                      box-shadow: none;
                    }
                  }
                }

                .answer-list-wrapper{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                  flex-direction: row;
                  padding-bottom: 10px;
  
                  .AnswerBox{
                    position: relative;
                    padding: 0 10px;
                    width: fit-content;
                    font-weight: 700;
                    text-transform: uppercase;
                    background: linear-gradient(180deg, #0F8EB7 0%, #074470 100%);
                    box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
                    color: $white;
                    border-radius: 23px;
                    padding: 10px 20px;
                    margin: 0 10px 20px 10px;
                    max-width: 310px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.format-light{
                      background: linear-gradient(180deg, #FDFBF5 0%, #FAF3DD 19.79%, #FAF3DD 80.73%, #F7E9C0 100%);
                      color: $dark-orange;
                    }

                    .text{
                      p{
                        margin: 0;
                        font: inherit;
                      }
                    }

                    .image{
                      font-size: 0;

                      img{
                        max-height: 70px;
                      }
                    }

                    &>div:nth-child(2){
                      margin-left: 20px;
                    }
  
                    .ErrorIcon, .RightIcon{
                      position: absolute;
                      pointer-events: none;
                      transform: translate(-50%, -50%);
                      animation-name: fadeInOut;
                      animation-duration: 1s;
                      animation-fill-mode: forwards;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.finish-screen{
        opacity: 0;
        display: flex;
        flex-direction: column;

        &.visible{
          opacity: 1;
          transition: .2s opacity;
        }

        .bottom-wrapper{
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          .upper-wrapper{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .text-wrapper{
              text-align: center;
              padding: 30px 20px 0;
            }
    
            .image-wrapper{
              padding: 40px 40px 0;
    
              img{
                display: block;
                width: 100%;
                filter: drop-shadow(0px 0px 32px rgba(250, 252, 252, 0.6));
                max-height: calc(100vh - 490px);
                object-fit: cover;

                @media (orientation: landscape) and (max-height: 450px) {
                  max-height: unset;
                  width: 40%;
                  margin-left: auto;
                  margin-right: auto;
                }

                @media (min-aspect-ratio: 432/872) {
                  max-height: unset;
                  width: 90%;
                  max-width: 500px;
                  margin-left: auto;
                  margin-right: auto;
                }
              }
            }
    
            .title-wrapper{
              font-family: $titling-font-family;
              font-size: 28px;
              font-weight: 900;
              padding: 40px 20px 0;
              text-align: center;
    
              &.QRcta{
                padding-top: 20px;
              }
            }

            .location-wrapper{
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: .9em;
              padding-top: 5px;

              img, svg{
                height: 18px;
                width: auto;
                margin-right: 8px;
                margin-bottom: 4px;
              }
            }
          }
  
          .lower-wrapper{
            .qr-action-wrapper{
              padding: 40px 20px 0;
              text-align: center;
            }
    
            .repeat-wrapper{
              text-align: center;
              margin-top: 20px;
              padding-bottom: 40px;
            }
          }
        }
      }
    }
  }

  &.softInvisible{
    .wrapper .Moment.type-dialogue.visible .total-wrapper .image-wrapper img{
      opacity: 0;
      transition: .1s opacity;
    }

    .wrapper .Moment.type-dialogue.visible .total-wrapper .bottom-wrapper .balloon-wrapper{
      .name, .text{
        opacity: 0;
        transition: .2s opacity;
      }
    }
  }

  &.onboardingStation{
    .wrapper .Moment .exit-wrapper{
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.ios{
  .Station .wrapper .Moment.type-dialogue .total-wrapper .image-wrapper{
    margin-top: -100px;

    img{
      max-height: calc(100vh - 420px);
    }
  }

  .Station .wrapper .Moment.type-itemGiven .total-wrapper .bottom-wrapper .image-wrapper img, .Station .wrapper .Moment.type-skinGiven .total-wrapper .bottom-wrapper .image-wrapper img{
    max-width: calc(81vh - 370px);
  }

  .Station .wrapper .Moment.finish-screen .bottom-wrapper .upper-wrapper .image-wrapper img{
    max-height: calc(100vh - 550px);
  }

  .Station{
    min-height: -webkit-fill-available;

    .wrapper{
      min-height: -webkit-fill-available;

      .Moment{
          min-height: -webkit-fill-available;

          .total-wrapper{
            min-height: -webkit-fill-available;
        }
      }
    }
  }
}