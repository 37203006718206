@import "../../style/variables";

.CustomButton{
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  outline: none;
}

.CustomA, .CustomLink{
  &, &:link, &:hover, &:visited, &:active, &:focus{
    outline: none;
    color: inherit;
    text-decoration: none;
  }
}

.CustomButton, .CustomA, .CustomLink{
  cursor: pointer;
  
  &.kind-primary, &.kind-secondary, &.kind-outline{
    border-radius: 1.3em;
    padding: .5em 1.333em .6em;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    font-weight: bold;

    &.text-less{
      padding-left: .65em;
      padding-right: .65em;
    }
  }

  span.text{
    flex-grow: 1;
    text-align: center;
  }

  span.pre-icon:not(:last-child){
    margin-right: .6em;
  }

  span.post-icon:not(:first-child){
    margin-left: .6em;
  }

  span.pre-icon, span.post-icon{
    display: inline-flex;
    min-height: 1.528em;
    align-items: center;

    svg, img{
      margin-top: -1px;
      height: 1.3em;
      width: auto;
    }
  }

  &.kind-primary{
    background: $orange-gradient;
    color: white;
  }

  &.kind-secondary{
    background: $lake-gradient;
    color: $dark-sapphire;
  }

  &.kind-outline{
    border: 2px solid;
  }

  &.kind-text{
    font-size: .7em;
    padding: 1.15em;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
  }
}