@import "../../style/variables";

.Collection{
  position: fixed;
  background-color: $dark-sapphire;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 52vh;
  margin-left: auto;
  margin-right: auto;

  &.otherUser{
    .room{
      bottom: 0;
    }
  }

  .room{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 90px;
    background-color: $sapphire;
    display: flex;
    flex-direction: column;

    .user-explain{
      padding: 27px 20px 20px;
      text-align: center;
      color: white;

      p{
        margin-top: 0;
        margin-bottom: 20px;

        &:first-child{
          font-family: $titling-font-family;
          font-size: 1.5em;
        }

        a{
          color: $gold;
        }
      }
    }

    .padder{
      flex-grow: 1;
      min-height: 30px;
    }

    .image{
      aspect-ratio: 0.585;
      position: relative;

      .layer{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .spots{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        aspect-ratio: 0.77;

        .spot{
          position: absolute;
          aspect-ratio: 1;

          &.s0{
            width: 16.4%;
            top: 3.6%;
            left: 6.9%;
          }

          &.s1{
            width: 16.4%;
            top: 19.9%;
            left: 6.9%;
          }

          &.s2{
            width: 16.4%;
            top: 3.6%;
            left: 76.7%;
          }

          &.s3{
            width: 16.4%;
            top: 19.9%;
            left: 76.7%;
          }

          &.s4{
            width: 43.1%;
            top: 1.4%;
            left: 28.5%;
          }

          &.s5{
            width: 16.4%;
            top: 50%;
            left: 24.4%
          }

          &.s6{
            width: 16.4%;
            top: 50%;
            left: 42%
          }

          &.s7{
            width: 16.4%;
            top: 50%;
            left: 59.7%
          }

          &.s8{
            width: 16.4%;
            top: 50%;
            left: 77.4%
          }

          &.s9{
            width: 20.5%;
            top: 81%;
            left: 4%
          }

          &.s10{
            width: 20.5%;
            top: 81%;
            left: 29%
          }

          &.s11{
            width: 20.5%;
            top: 81%;
            left: 54.1%
          }

          .hotspot{
            width: 90%;
            height: 90%;
            margin: 5%;
            border-radius: 23%;
            border: 2px dashed gray;
            background-color: rgba(255, 255, 255, .4);
            position: absolute;
            top: 0;
            left: 0;

            @keyframes glow {
              from {
                transform: scale(0.9);
              }
              to {
                transform: scale(1);
              }
            }

            &.inviting{
              border-color: #F5B40E;
              background-color: transparentize(#F5B40E, $amount: 0.5);
              animation-name: glow;
              animation-duration: .7s;
              animation-iteration-count: infinite;
              animation-direction: alternate;
            }
          }

          .item{
            width: 100%;
            height: 100%;
            appearance: none;
            background-color: transparent;
            border: none;
            padding: 0 3px;

            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .additionalAction{
    position: fixed;
    top: 54px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    height: 44px;
    border-radius: 22px;
    background: linear-gradient(180deg, #074470 0%, #240333 100%);
    box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    appearance: none;
    border: none;
    
    &, &:link, &:visited, &:active, &:hover, &:focus{
      color: white;
      text-decoration: none;
    }

    svg{
      margin-bottom: 2px;
      margin-left: 10px;
    }
  }

  .shareAction{
    position: fixed;
    top: 54px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 44px;
    border-radius: 22px;
    background: linear-gradient(180deg, #F5B40E 0%, #FD671A 100%);
    box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    appearance: none;
    border: none;

    .text{
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      font-size: .7em;

      span{
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    
    &, &:link, &:visited, &:active, &:hover, &:focus{
      color: white;
      text-decoration: none;
    }
  }

  &.grayed{
    .room{
      .image .layer, .padder, .image .spots .spot .item{
        filter: grayscale(0.8);
      }
    }
  }
}

.ios{
  .Collection .room .image{
    padding-top: 171%;
  }

  .Collection .room .image .item{
    padding-top: 130%;
  }

  .Collection .room .padder{
    min-height: unset;
  }
}