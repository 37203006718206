@import "../../style/variables";

.MuseumItems{
  min-height: 100vh;
  background-image: linear-gradient(180deg, #074470 0%, #240333 100%);
  color: $white;

  .header{
    position: relative;
    padding: 54px 23px 42px;

    .exit-wrapper{
      position: absolute;

      .CustomButton{
        border-radius: 10px;
        margin-top: -3px;
      }
    }

    .title-wrapper{
      text-align: center;
      font-family: $titling-font-family;
      font-weight: 900;
      font-size: 28px;
      padding: 0 60px;
    }
  }

  .list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px 60px;

    .item{
      width: 140px;
      min-width: 140px;
      max-width: 140px;
      height: 140px;
      overflow: hidden;
      padding: 10px;
      border-radius: 20px;
      background: $light-paper-gradient;
      box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
      margin: 10px;
      appearance: none;
      cursor: pointer;
      border: none;

      &.notInCollection{
        opacity: .3;

        img{
          filter: grayscale(1);
        }
      }

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: drop-shadow(0px 0px 4px rgba(253, 103, 26, 0.33));
      }
    }
  }
}

.ios .MuseumItems{
  min-height: -webkit-fill-available;
}