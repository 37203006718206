@import "../../style/variables";

.Home{
  height: 100vh;
  width: 100vw;

  iframe + div {
    border: none !important;
  }

  .gm-style .gm-style-iw-c {
    padding: 0;
    max-height: unset !important;
    max-width: 330px !important;
    width: 100% !important;
    border-radius: 37px;
    background-color: $light-gold;
    box-shadow: 0px 4px 8px rgba(253, 103, 26, 0.4);
  }

  .gm-style .gm-style-iw-d {
    padding: 0 !important;
    overflow: hidden !important;
    font-size: 0;
    line-height: 0;
    max-height: unset;
  }

  .gm-style .gm-style-iw-d  + .gm-ui-hover-effect{
    display: none !important;
  }

  .gm-style .gm-style-iw-tc::after{
    background-color: $light-gold;
  }

  .pinpoint-info-window{
    width: 100%;
    box-sizing: border-box;

    .wrapper{
      padding: 5px;
      width: 100%;
      box-sizing: border-box;

      img{
        width: 100%;
        height: 190px;
        object-fit: cover;
        border-radius: 32px;
      }

      .text-wrapper{
        padding: 26px 20px 30px;
        font-size: 1rem;
        line-height: 1.2;

        h3{
          margin-top: 0;
          color: $dark-sapphire;
          font-weight: 900;
          font-size: 20px;
          font-family: $titling-font-family;
          margin-bottom: 10px;
        }

        .museums-wrapper{
          font-size: 13px;
          margin-bottom: 27px;

          span.museum-link{
            a{
              font-weight: bold;
              color: $orange;
              text-transform: uppercase;

              &:focus{
                outline: none;
              }
            }

            &:not(:last-child)::after{
              content: ", ";              
            }
          }
        }

        .more-wrapper{
          font-size: 14px;
          padding-top: 20px;
          margin-top: 15px;
          border-top: .5px solid $dark-sapphire;
          
          .too-far, .no-geolocation, .here{
            text-align: center;
            font-weight: bold;
            color: $dark-sapphire;

            p{
              margin-top: 0;

              &:last-child{
                margin-bottom: 0;
              }
            }
          }

          .no-geolocation, .here{
            p{
              margin-bottom: 10px;
            }

            .CustomButton{
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  .geolocationButton{
    position: fixed;
    top: 50px;
    left: 40px;
    appearance: none;
    background: linear-gradient(180deg, #074470 0%, #240333 100%);
    box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
    border-radius: 10px;
    border: none;
    width: 40px;
    height: 40px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg{
      height: 24px;
      width: 24px;
    }

    @keyframes pulse {
      0%{
        opacity: 0;
        transform: scale(.5);
      }
      50%{
        opacity: 1;
        transform: scale(.75);
      }
      100%{
        opacity: 0;
        transform: scale(1);
      }
    }

    .loading{
      height: 24px;
      width: 24px;
      border-radius: 12px;
      display: block;
      border: 2px solid white;
      background-color: rgba(255, 255, 255, .2);
      animation-name: pulse;
      animation-duration: 1s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
  }
}

.pinpointOverlay{
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  transition: 0.3s opacity;
  display: flex;
  flex-direction: column;
}

.PinpointModal{
  flex-grow: 1;
  background-color: $light-gold;
  padding: 64px 25px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .skin-congratulation{
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    color: $dark-sapphire;
    margin-top: -10px;

    .left{
      width: 30%;
      min-width: 30%;
      max-width: 30%;
      padding-right: 20px;

      img{
        width: 100%;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 2px solid $orange;
        object-fit: cover;
        object-position: bottom;
      }
    }

    .right{
      flex-grow: 1;

      p{
        margin: 0;
        font-size: .8em;
        line-height: 1.3;

        &.congratulation{
          font-weight: bold;
          color: $orange;
        font-size: .9em;
      }
      }
    }
  }

  img.pinpointImage{
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 32px;
  }

  .close-wrapper{
    padding-bottom: 26px;
    display: flex;
    justify-content: flex-end;

    button{
      color: $orange;
      appearance: none;
      cursor: pointer;
      background-color: transparent;
      padding: 0;
      border: none;
    }
  }

  .text-wrapper{
    padding: 26px 0 0;
    font-size: 1rem;
    line-height: 1.2;

    h3{
      margin-top: 0;
      color: $dark-sapphire;
      font-weight: 900;
      font-size: 26px;
      font-family: $titling-font-family;
      margin-bottom: 10px;
    }

    .museums-wrapper{
      font-size: 14px;
      margin-bottom: 27px;

      span.museum-link{
        a{
          font-weight: bold;
          color: $orange;
          text-transform: uppercase;

          &:focus{
            outline: none;
          }
        }

        &:not(:last-child)::after{
          content: ", ";              
        }
      }
    }

    .detail-text-wrapper{
      line-height: 1.4;
    }
  }

  .medias-wrapper{
    font-size: .9em;
    border-top: 1px solid;
    color: $dark-sapphire;
    text-align: center;
    margin-top: 2rem;
    padding-top: .5rem;

    p{
      margin: 1.5rem 0;
    }
  }
}

.foggy-map{
  [role="region"] img:not([role="presentation"]) {
      transition: 1.5s opacity;
  }
}

.mediaOverlay{
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  padding: 40px 15px;
  transition: .3s opacity;
  padding: 0;

    
  .MediaModal{
    background: $light-gold;
    overflow: auto;
    outline: none;
    box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
    min-height: 100%;
    transition: .3s transform;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
    background-image: $dark-sapphire-gradient;
    padding: 0;

    .inner-wrapper{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;

      .Media{
        flex-grow: 1;
      }

      .back-wrapper{
        color: $white;
        text-align: center;
      }
    }
  
  }
}

.geoErrorOverlay{
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  padding: 40px 15px;
  transition: .3s opacity;
  padding: 50px;
    
  .GeoError{
    background: $light-gold;
    overflow: auto;
    outline: none;
    box-shadow: 0px 3px 6px rgba(9, 85, 128, 0.33);
    transition: .3s transform;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
    background-image: $light-gold;
    padding: 0;

    .inner-wrapper{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 30px;
      text-align: center;

      .mod-text{
        padding-bottom: 30px;
      }
    }
  }
}