@import "../../style/variables";

.QrScan{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  section.camera{
    height: 100%;

    &>div{
      padding-top: unset !important;
      height: 100%;

      video{
        object-fit: cover !important;
      }
    }
  }

  .infoBox{
    position: fixed;
    z-index: 10;
    left: 10px;
    right: 10px;
    bottom: 70px;
    padding: 22px 22px 26px;
    background-color: $white;
    border: 2px solid $lake;
    box-shadow: 0px 0px 6px rgba(11, 119, 161, 0.33);
    border-radius: 32px;
  }
}